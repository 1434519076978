import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// validation Fuction'
import validation from './RegisterValidation';
// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  // const handleClick = () => {
  //   navigate('/dashboard', { replace: true });    onClick={handleClick}
  // };


  const [values, setValues] = useState({
    name: '',
    email: '',
    password: ''
  })
  const [errors, setErrors] = useState({})
  const HandleInput = (event) => {
    setValues(prev => ({ ...prev, [event.target.name]: [event.target.value] }))
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validation(values));

  }


  return (
    <>
      <form action='' onSubmit={handleSubmit}>
      <Stack spacing={3}>

      {errors.name && <span className='text-danger'>{errors.name}</span>}
      <TextField name="name" label="name"  onChange={HandleInput}/>
      {errors.email && <span className='text-danger'>{errors.email}</span>}
        <TextField name="email" label="Email address"  onChange={HandleInput}/>


        {errors.password && <span className='text-danger'>{errors.password}</span>}

        <TextField
          name="password"
          label="Password"
          onChange={HandleInput}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" >
        Register Now
      </LoadingButton>
      </form>
    </>
  );
}
